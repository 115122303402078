//Main colors
$primary: #ea5a0b;
$secondary: #000000;
$tertiary: #ffffff;
$dark: #55433b;

//Generated palettes: https://mycolor.space/?hex=%23EA5A0B&sub=1
//Generic Gradient
$gg1: #ea5a0b;
$gg2: #e13859;
$gg3: #b54084;
$gg4: #754f90;
$gg5: #3e517c;
$gg6: #2f4858;
//Matching Gradient
$mg1: #ea5a0b;
$mg2: #b77300;
$mg3: #7c8000;
$mg4: #338400;
$mg5: #008334;
$mg6: #007d64;

//============!Use only in this file!============
//Images path.
$logo1: 'images/logo_redhator_1.png';
$logo2: 'images/logo_redhator_1_fi_1.png';
$logo3: 'images/logo_redhator_1_mc_1.png';
$logo4: 'images/logo_redhator_1_mc_2.png';
$logo5: 'images/logo_redhator_2.png';
$logo6: 'images/logo_redhator_2_fi_1.png';
$logo7: 'images/logo_redhator_3.png';
$logo8: 'images/logo_redhator_3_fi_1.png';
$logo9: 'images/logo_redhator_3_fi_2.png';
//============!Use only in this file!============

//Images for use in styles if variables.scss imported
$logo_v1_1: url('../../public/' + $logo1);
$logo_v1_2: url('../../public/' + $logo2);
$logo_v1_3: url('../../public/' + $logo3);
$logo_v1_4: url('../../public/' + $logo4);
$logo_v2_1: url('../../public/' + $logo5);
$logo_v2_2: url('../../public/' + $logo6);
$logo_v3_1: url('../../public/' + $logo7);
$logo_v3_2: url('../../public/' + $logo8);
$logo_v3_3: url('../../public/' + $logo9);

//Application font size
$fontSize: 1em;

//Variables for use in .js files if variables.scss imported
:export {
  logo_v1_1: $logo1;
  logo_v1_2: $logo2;
  logo_v1_3: $logo3;
  logo_v1_4: $logo4;
  logo_v2_1: $logo5;
  logo_v2_2: $logo6;
  logo_v3_1: $logo7;
  logo_v3_2: $logo8;
  logo_v3_3: $logo9;
  fontSize: $fontSize;
}
