@import 'scss/variables.scss';

// body {
//   overflow-x: hidden;
//   font-family: 'Roboto Slab', 'Helvetica Neue', Helvetica, Arial, sans-serif;
// }

p {
  line-height: 1.75;
}

a {
  color: #fed136;
}

a:hover {
  color: #fec503;
}

.text-primary {
  color: #fed136 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700 !important;
  font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
}

// span {
//   font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
// }

section {
  padding: 25px 0;
}

section .col-md-4 {
  padding-bottom: 20px;
}

section h2.section-heading {
  font-size: 40px !important;
  margin-top: 0 !important;
  margin-bottom: 15px !important;
}

section h3.section-subheading {
  font-size: 16px !important;
  font-weight: 400 !important;
  font-style: italic !important;
  margin-bottom: 45px;
  text-transform: none !important;
  font-family: 'Droid Serif', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
}

@media (min-width: 768px) {
  section {
    padding: 50px 0;
  }

  section .col-md-4 {
    padding-bottom: 0px;
  }
}

// input {
//   padding: 0.2em !important;
// }

.btn {
  font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  font-weight: 700 !important;
}

.btn-xl {
  font-size: 18px !important;
  padding: 20px 40px !important;
}

.btn-primary {
  background-color: $primary !important;
  border-color: $primary !important;
}

.btn-primary:hover {
  background-color: $secondary !important;
  border-color: $secondary !important;
  color: white;
}

.btn-primary:active,
.btn-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(10, 39, 45, 0.5) !important;
}

.btn-head {
  background-color: $primary !important;
  color: white;
  border-color: $primary !important;
  font-size: 12px !important;
  // padding: 3px 5px !important;
}

.btn-head:hover {
  background-color: $secondary !important;
  border-color: $secondary !important;
  color: white;
}

.btn-head:active,
.btn-head:focus {
  box-shadow: 0 0 0 0.2rem rgba(10, 39, 45, 0.5) !important;
}

::-moz-selection {
  background: #fed136;
  text-shadow: none;
}

::selection {
  background: #fed136;
  text-shadow: none;
}

img::selection {
  background: transparent;
}

img::-moz-selection {
  background: transparent;
}

#mainNav {
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: $secondary;
}

#mainNav .navbar-toggler {
  font-size: 12px;
  right: 0;
  padding: 13px;
  text-transform: uppercase;
  color: white;
  border: 0;
  background-color: $primary;
  font-family: 'Roboto+Slab';
}

#mainNav .navbar-toggler:hover {
  background-color: $secondary;
}

#mainNav .navbar-brand {
  // color: #fff;
  // text-shadow: -0.1px -0.1px 0 $secondary, 0.1px -0.1px 0 $secondary,
  //   -0.1px 0.1px 0 $secondary, 0.1px 0.1px 0 $secondary;
  // font-family: 'Verdana', 'Helvetica Neue', Helvetica, Arial, cursive;
  // background-image: url('~/public/images/logo_sisritel_punto_200x200_fondoincomp.png');
  // background-repeat: no-repeat;
  // background-size: 50px 40px;
  // padding-top: 10px;
}

#mainNav .navbar-brand:hover {
  color: $secondary;
  text-shadow: -0.1px -0.1px 0 $secondary, 0.1px -0.1px 0 $secondary, -0.1px 0.1px 0 $secondary,
    0.1px 0.1px 0 $secondary;
}

#mainNav .navbar-nav .nav-item .nav-link {
  font-size: 90%;
  font-weight: 400;
  padding: 0.75em 0;
  letter-spacing: 1px;
  color: #fff;
  text-shadow: -0.1px -0.1px 0 $secondary, 0.1px -0.1px 0 $secondary, -0.1px 0.1px 0 $secondary,
    0.1px 0.1px 0 $secondary;
  font-family: 'Roboto+Slab';
}

#mainNav .navbar-nav .nav-item .nav-link.active,
#mainNav .navbar-nav .nav-item .nav-link:hover {
  color: $primary;
  text-shadow: -0.1px -0.1px 0 $primary, 0.1px -0.1px 0 $primary, -0.1px 0.1px 0 $primary,
    0.1px 0.1px 0 $primary;
}

@media (min-width: 992px) {
  #mainNav {
    -webkit-transition: padding-top 0.3s, padding-bottom 0.3s;
    -moz-transition: padding-top 0.3s, padding-bottom 0.3s;
    transition: padding-top 0.3s, padding-bottom 0.3s;
    border: none;
    background: linear-gradient(to right, rgba(255, 255, 255, 0.2), $secondary);
  }

  #mainNav .navbar-brand {
    // font-size: 1.75em;
    // -webkit-transition: all 0.3s;
    // -moz-transition: all 0.3s;
    // transition: all 0.3s;
    // background-image: url('~/public/images/logo_sisritel_punto_200x200.png');
    // background-repeat: no-repeat;
    // background-size: 60px 50px;
    // margin-top: 10px;
  }

  #mainNav .navbar-nav .nav-item .nav-link {
    padding: 1.1em 1em !important;
  }

  #mainNav.navbar-shrink {
    padding-top: 0;
    padding-bottom: 0;
    background: linear-gradient(to right, rgba(33, 37, 41, 1), rgba(33, 37, 41, 1));
  }

  #mainNav.navbar-shrink .navbar-brand {
    font-size: 1.25em;
    color: #fff;
    text-shadow: none;
    // background-image: url('~/public/images/logo_sisritel_punto_200x200_fondoincomp.png');
    background-repeat: no-repeat;
    background-size: 50px 40px;
    // margin-top: 12px;
    // padding-bottom: 10px;
  }

  #mainNav.navbar-shrink .navbar-brand:hover {
    color: $secondary;
  }
}

header.masthead {
  text-align: center;
  color: white;
  background-color: $primary;
  // background-image: url('~/public/images/Fotor_AI.png');
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

header.masthead .intro-text {
  padding-top: 350px;
}

header.masthead .intro-text .intro-lead-in {
  font-size: 22px;
  font-style: italic;
  line-height: 22px;
  margin-bottom: 25px;
  font-family: 'Verdana', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

header.masthead .intro-text .intro-heading {
  font-size: 50px;
  font-weight: 700;
  line-height: 50px;
  margin-bottom: 25px;
  font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  background: linear-gradient(to top right, $primary, $secondary, #ffffff);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media (min-width: 768px) {
  header.masthead .intro-text {
    padding-top: 450px;
    padding-bottom: 50px;
  }

  header.masthead .intro-text .intro-lead-in {
    font-size: 40px;
    font-style: italic;
    line-height: 40px;
    margin-bottom: 25px;
    font-family: 'Verdana', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  header.masthead .intro-text .intro-heading {
    font-size: 65px;
    font-weight: 700;
    line-height: 65px;
    margin-bottom: 50px;
    font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    background: linear-gradient(to right, $primary, $secondary, #ffffff);
    background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.service-heading {
  margin: 15px 0;
  text-transform: none;
}

#portfolio .portfolio-item {
  right: 0;
  margin: 0 0 15px;
}

#portfolio .portfolio-item .portfolio-link {
  position: relative;
  display: block;
  max-width: 400px;
  margin: 0 auto;
  cursor: pointer;
}

#portfolio .portfolio-item .portfolio-link .portfolio-hover {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-transition: all ease 0.5s;
  -moz-transition: all ease 0.5s;
  transition: all ease 0.5s;
  opacity: 0;
  background: rgba(236, 134, 1, 0.9);
}

#portfolio .portfolio-item .portfolio-link .portfolio-hover:hover {
  opacity: 1;
}

#portfolio .portfolio-item .portfolio-link .portfolio-hover .portfolio-hover-content {
  font-size: 20px;
  position: absolute;
  top: 50%;
  width: 100%;
  height: 20px;
  margin-top: -25px;
  text-align: center;
  color: white;
}

#portfolio .portfolio-item .portfolio-link .portfolio-hover .portfolio-hover-content i {
  margin-top: -12px;
}

#portfolio .portfolio-item .portfolio-link .portfolio-hover .portfolio-hover-content h3,
#portfolio .portfolio-item .portfolio-link .portfolio-hover .portfolio-hover-content h4 {
  margin: 0;
}

#portfolio .portfolio-item .portfolio-caption {
  max-width: 400px;
  margin: 0 auto;
  padding: 25px;
  text-align: center;
  background-color: #fff;
}

#portfolio .portfolio-item .portfolio-caption h4 {
  margin: 0;
  text-transform: none;
}

#portfolio .portfolio-item .portfolio-caption p {
  font-size: 16px;
  font-style: italic;
  margin: 0;
  font-family: 'Droid Serif', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

#portfolio * {
  z-index: 2;
}

@media (min-width: 767px) {
  #portfolio .portfolio-item {
    margin: 0 0 30px;
  }
}

.portfolio-modal {
  padding-right: 0px !important;
}

.portfolio-modal .modal-dialog {
  margin: 1rem;
  max-width: 100vw;
}

.portfolio-modal .modal-content {
  padding: 50px 0;
  text-align: center;
}

.portfolio-modal .modal-content h2 {
  font-size: 3em;
  margin-bottom: 15px;
}

.portfolio-modal .modal-content p {
  margin-bottom: 30px;
}

.portfolio-modal .modal-content p.item-intro {
  font-size: 16px;
  font-style: italic;
  margin: 20px 0 30px;
  font-family: 'Droid Serif', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.portfolio-modal .modal-content ul.list-inline {
  margin-top: 0;
  margin-bottom: 30px;
}

.portfolio-modal .modal-content img {
  margin-bottom: 30px;
}

.portfolio-modal .modal-content button {
  cursor: pointer;
}

.portfolio-modal .close-modal {
  position: absolute;
  top: 25px;
  right: 25px;
  width: 50px;
  height: 25px;
  cursor: pointer;
  background-color: transparent;
}

.portfolio-modal .close-modal:hover {
  opacity: 0.3;
}

.portfolio-modal .close-modal .lr {
  /* Safari and Chrome */
  z-index: 1051;
  width: 1px;
  height: 25px;
  margin-left: 35px;
  /* IE 9 */
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  background-color: #212529;
}

.portfolio-modal .close-modal .lr .rl {
  /* Safari and Chrome */
  z-index: 1052;
  width: 1px;
  height: 25px;
  /* IE 9 */
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  background-color: #212529;
}

.timeline {
  position: relative;
  padding: 0;
  list-style: none;
}

.timeline:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 40px;
  width: 2px;
  margin-left: -1.5px;
  content: '';
  background-color: #e9ecef;
}

.timeline > li {
  position: relative;
  min-height: 50px;
  margin-bottom: 50px;
}

.timeline > li:after,
.timeline > li:before {
  display: table;
  content: ' ';
}

.timeline > li:after {
  clear: both;
}

.timeline > li .timeline-panel {
  position: relative;
  float: right;
  width: 100%;
  padding: 0 20px 0 100px;
  text-align: left;
}

.timeline > li .timeline-panel:before {
  right: auto;
  left: -15px;
  border-right-width: 15px;
  border-left-width: 0;
}

.timeline > li .timeline-panel:after {
  right: auto;
  left: -14px;
  border-right-width: 14px;
  border-left-width: 0;
}

.timeline > li .timeline-image {
  position: absolute;
  z-index: 100;
  left: 0;
  width: 80px;
  height: 80px;
  margin-left: 0;
  text-align: center;
  color: white;
  border: 7px solid #e9ecef;
  border-radius: 100%;
  background-color: $secondary;
}

.timeline > li .timeline-image h4 {
  font-size: 10px;
  line-height: 14px;
  margin-top: 12px;
}

.timeline > li.timeline-inverted > .timeline-panel {
  float: right;
  padding: 0 20px 0 100px;
  text-align: left;
}

.timeline > li.timeline-inverted > .timeline-panel:before {
  right: auto;
  left: -15px;
  border-right-width: 15px;
  border-left-width: 0;
}

.timeline > li.timeline-inverted > .timeline-panel:after {
  right: auto;
  left: -14px;
  border-right-width: 14px;
  border-left-width: 0;
}

.timeline > li:last-child {
  margin-bottom: 0;
}

.timeline .timeline-heading h4 {
  margin-top: 0;
  color: inherit;
}

.timeline .timeline-heading h4.subheading {
  text-transform: none;
}

.timeline .timeline-body > ul,
.timeline .timeline-body > p {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .timeline:before {
    left: 50%;
  }

  .timeline > li {
    min-height: 100px;
    margin-bottom: 100px;
  }

  .timeline > li .timeline-panel {
    float: left;
    width: 41%;
    padding: 0 20px 20px 30px;
    text-align: right;
  }

  .timeline > li .timeline-image {
    left: 50%;
    width: 100px;
    height: 100px;
    margin-left: -50px;
  }

  .timeline > li .timeline-image h4 {
    font-size: 13px;
    line-height: 18px;
    margin-top: 16px;
  }

  .timeline > li.timeline-inverted > .timeline-panel {
    float: right;
    padding: 0 30px 20px 20px;
    text-align: left;
  }
}

@media (min-width: 992px) {
  .timeline > li {
    min-height: 150px;
  }

  .timeline > li .timeline-panel {
    padding: 0 20px 20px;
  }

  .timeline > li .timeline-image {
    width: 150px;
    height: 150px;
    margin-left: -75px;
  }

  .timeline > li .timeline-image h4 {
    font-size: 18px;
    line-height: 26px;
    margin-top: 30px;
  }

  .timeline > li.timeline-inverted > .timeline-panel {
    padding: 0 20px 20px;
  }
}

@media (min-width: 1200px) {
  .timeline > li {
    min-height: 170px;
  }

  .timeline > li .timeline-panel {
    padding: 0 20px 20px 100px;
  }

  .timeline > li .timeline-image {
    width: 170px;
    height: 170px;
    margin-left: -85px;
  }

  .timeline > li .timeline-image h4 {
    margin-top: 40px;
  }

  .timeline > li.timeline-inverted > .timeline-panel {
    padding: 0 100px 20px 20px;
  }
}

.team-member {
  margin-bottom: 50px;
  text-align: center;
}

.team-member img {
  width: 225px;
  height: 225px;
  border: 7px solid #fff;
}

.team-member h4 {
  margin-top: 25px;
  margin-bottom: 0;
  text-transform: none;
}

.team-member p {
  margin-top: 0;
}

section#contact {
  background-color: #17333b;
  background-image: url('~/public/images/background_contact_form_DVinci.png');
  background-repeat: no-repeat;
  background-position: center;
}

section#contact .section-heading {
  color: #fff;
}

section#contact .form-group {
  margin-bottom: 25px;
}

section#contact .form-group input,
section#contact .form-group textarea {
  padding: 20px;
}

section#contact .form-group input.form-control {
  height: auto;
}

section#contact .form-group textarea.form-control {
  height: 190px;
}

section#contact .form-control:focus {
  border-color: #fed136;
  box-shadow: none;
}

section#contact ::-webkit-input-placeholder {
  font-weight: 700;
  color: #ced4da;
  font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

section#contact :-moz-placeholder {
  font-weight: 700;
  color: #ced4da;
  /* Firefox 18- */
  font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

section#contact ::-moz-placeholder {
  font-weight: 700;
  color: #ced4da;
  /* Firefox 19+ */
  font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

section#contact :-ms-input-placeholder {
  font-weight: 700;
  color: #ced4da;
  font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

footer {
  padding: 25px 0;
  text-align: center;
}

footer span.copyright {
  font-size: 90%;
  line-height: 40px;
  text-transform: none;
  font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

footer ul.quicklinks {
  font-size: 90%;
  line-height: 40px;
  margin-bottom: 0;
  text-transform: none;
  font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

ul.social-buttons {
  margin-bottom: 0;
}

ul.social-buttons li a {
  font-size: 20px;
  line-height: 40px;
  display: block;
  width: 40px;
  height: 40px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  color: white;
  border-radius: 100%;
  outline: none;
  background-color: #212529;
}

ul.social-buttons li a:active,
ul.social-buttons li a:focus,
ul.social-buttons li a:hover {
  background-color: $secondary;
}

/*** Definidas por mi ****/

.fa .fa-circle {
  background-color: #0a272d;
}

.hidden {
  display: none;
}

.portfolio-modal-video-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}

.portfolio-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (max-width: 768px) {
  .portfolio-modal .modal-content h2 {
    font-size: 1.4em;
    margin-bottom: 5px;
  }

  .portfolio-modal .modal-content p.item-intro {
    margin: 20px 0 5px;
  }

  section h2.section-heading {
    font-size: 32px !important;
    margin-top: 0 !important;
    margin-bottom: 10px !important;
  }
}

/* esto es mío */

/* esto es que las marcas de los mapas con alertas pendientes sean intermitentes */
@keyframes fade {
  from {
    opacity: 0.3;
  }
}

.blinking {
  animation: fade 0.75s infinite alternate;
}

/* para el week day picker en programación */
.weekDays-selector input {
  display: none !important;
}

.weekDays-selector input[type='checkbox'] + label {
  display: inline-block;
  border-radius: 6px;
  background: #dddddd;
  height: 40px;
  width: 30px;
  color: #999999;
  margin-right: 3px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
}

.weekDays-selector input[type='checkbox']:checked + label {
  background: #2ad705;
  color: #ffffff;
}

/* para el solenoide picker en programación*/
.solenoide-selector input {
  display: none !important;
}

.solenoide-selector input[type='checkbox'] + label {
  display: inline-block;
  border-radius: 6px;
  background: #dddddd;
  padding: 5px;
  min-height: 50px;
  color: #999999;
  text-align: center;
  cursor: pointer;
}

/*.solenoide-selector input[type=checkbox]:checked + label {
  background: $secondary;
  color: #000000;
}*/

/* para el week day picker en programación list*/
.weekDays-list-selector input {
  display: none !important;
}

.weekDays-list-selector input[type='checkbox'] + label {
  display: inline-block;
  border-radius: 6px;
  background: #dddddd;
  height: 40px;
  width: 30px;
  color: #bbbbbb;
  margin-right: 3px;
  line-height: 40px;
  text-align: center;
}

.weekDays-list-selector input[type='checkbox']:checked + label {
  background: #777777;
  color: #dddddd;
}

/* para el solenoide picker en programación list*/
.solenoide-list-selector input {
  display: none !important;
}

.solenoide-list-selector input[type='checkbox'] + label {
  display: inline-block;
  border-radius: 6px;
  background: #dddddd;
  padding: 5px;
  min-height: 50px;
  color: #bbbbbb;
  text-align: center;
}

.solenoide-list-selector input[type='checkbox']:checked + label {
  background: #777777;
  color: #dddddd;
}

/* overlay en programacion */

.overlay_programa {
  position: fixed;
  /* Sit on top of the page content */
  display: none;
  /* Hidden by default */
  width: 100%;
  /* Full width (cover the whole page) */
  height: 100%;
  /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* Black background with opacity */
  z-index: 2;
  /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer;
  /* Add a pointer on hover */
}

/* working apis*/

.working_apis {
  position: fixed;
  z-index: 100055 !important;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(10, 10, 10, 0.7);
  text-align: center;
  background-image: url('~/public/images/loading@2x.gif');
  background-repeat: no-repeat;
  background-position: center;
  display: block;
  filter: none !important;
}

.text-working-apis {
  position: fixed;
  margin-top: 2em;
  z-index: 10056;
  top: 54%;
  right: 0px;
  width: 100%;
  height: 100%;
  color: #ccc;
  vertical-align: center;
  text-align: center;
  padding-left: 10%;
  padding-right: 10%;
}

/* para imprimir el contrato */
@media screen {
  #printSection {
    display: none;
  }
}

@media print {
  body * {
    visibility: hidden;
  }

  #printSection,
  #printSection * {
    visibility: visible;
  }

  #printSection {
    position: absolute;
    left: 0;
    top: 0;
  }
}

/* Cevitar flechas en inputs numericos */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.fixed_idris_head {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10000;
  background: white;
}

.fixed_idris_background_icons {
  background: $primary;
}

#str_wrapper {
  display: flex;
  position: fixed;
  height: 100%;
  width: 100%;
}

.logotext_sidebar {
  margin-top: 15px;

  text-align: center;
}

.closemenu_sidebar_right {
  margin-top: -10px;
  margin-right: 5px;
  text-align: right;
}

.closemenu_sidebar_left {
  margin-top: -10px;
  margin-left: 5px;
  text-align: left;
}

#str_wrapper main {
  padding: 10px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-wrap: break-word;
}

#str_wrapper .btn-toggle {
  cursor: pointer;
  width: 35px;
  height: 35px;
  background: #353535;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  display: none;
}

@media (max-width: 768px) {
  #str_wrapper .btn-toggle {
    display: flex;
  }
}

/* fin esto es mío */
.modal-body p,
li {
  text-align: start;
}

.queEsModal span {
  color: #008000;
}

/* section#imageFinal {
  min-height: 40vh;
  width: 100vw;
  background-image: url('~/public/images/Farm.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
} */

a {
  cursor: pointer;
}

.gmnoprint,
.gm-style-cc,
.gmnoscreen {
  display: none;
}

.leaflet-bottom img {
  display: none;
}

.leaflet-bottom {
  background-color: green;
  font-weight: bold;
  text-align: center;
}

.langBtn {
  cursor: pointer;
  width: 30px !important;
  height: 30px !important;
}

.alert-danger {
  display: table;
  margin-left: auto;
  margin-right: auto;
  color: red !important;
  background-color: rgba(255, 255, 255, 0.5) !important;
  border-color: rgba(255, 255, 255, 0) !important;
}

.alert-success {
  display: table;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 100px) and (max-width: 320px) {
  input[id*='custom-switch'] {
    transform: scale(0.5);
    margin-top: -0.5em;
    margin-bottom: -0.5em;
  }
}

@media (min-width: 320px) and (max-width: 375px) {
  input[id*='custom-switch'] {
    transform: scale(0.65);
  }
}

@media (min-width: 375px) and (max-width: 425px) {
  input[id*='custom-switch'] {
    transform: scale(0.8);
  }
}

@media (min-width: 425px) and (max-width: 600px) {
}

@media (min-width: 600px) and (max-width: 768px) {
}

@media (min-width: 768px) and (max-width: 992px) {
  input[id*='custom-switch'] {
    transform: scale(1.1);
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  input[id*='custom-switch'] {
    transform: scale(1.2);
  }
}

@media (min-width: 1200px) {
  input[id*='custom-switch'] {
    transform: scale(1.3);
  }
}

.text p {
  font-size: 1 * $fontSize !important;
  line-height: 1.2 * $fontSize !important;
  margin-bottom: 0 * $fontSize !important;
}

.transTable td {
  max-width: 22vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.transTable td:hover {
  background-color: rgba(255, 0, 0, 0.05);
  overflow: none;
  text-overflow: none;
  white-space: normal;
}

.scroll {
  height: 55vh;
  overflow: auto;
}

.noActive:not(:active) {
  pointer-events: none;
  z-index: -9;
  position: relative;
  border: none;
}

.noAttributes .leaflet-control-attribution {
  display: none;
}

.checkValidation input:invalid:required:focus,
select:invalid:required:focus {
  border: 1px solid lightpink;
  box-shadow: 0px 0px 5px lightpink inset;
}

.checkValidation input:required:focus,
select:required:focus {
  border: 1px solid lightgreen;
  box-shadow: 0px 0px 5px lightgreen inset;
}

.primary {
  background-color: $primary !important;
  border-color: $primary !important;
  color: white;
}

.dark {
  background-color: $dark !important;
  border-color: $dark !important;
  color: white;
}

.secondary {
  background-color: $secondary !important;
  border-color: $secondary !important;
  color: white;
}

#actuatorCheck:disabled {
  opacity: 1;
}

#actuatorCheck,
#autoUse {
  background-color: red;
  background-image: url(~/public/images/round.svg);
  border-color: red;
}

#actuatorCheck:checked,
#autoUse:checked {
  background-color: green;
  background-image: url(~/public/images/round.svg);
  border-color: green;
}

input[id*='custom-switch'],
#c-switch {
  cursor: pointer;
  box-shadow: none;
}

input[id*='custom-switch'],
#c-switch {
  background-color: red !important;
  background-image: url(~/public/images/round.svg) !important;
  border-color: red !important;
}

input[id*='custom-switch']:checked,
#c-switch:checked {
  background-color: green !important;
  background-image: url(~/public/images/round.svg) !important;
  border-color: green !important;
}

input[id*='custom-switch'] {
  width: 180px !important;
  height: 40px !important;
}

input[id*='custom-switch']:focus,
#autoUse:focus,
#c-switch:focus {
  box-shadow: none !important;
}

#actuatorCheck:checked {
  background-color: green;
  background-image: none;
}

#actuatorCheck {
  background-color: red;
  background-image: none;
}

#stateCheck:disabled {
  background-color: gray !important;
  border-color: gray !important;
  background-image: none;
}

#stateCheck:checked {
  background-color: green;
  border-color: green;
  background-image: none;
}

#stateCheck {
  background-color: red;
  border-color: red;
  background-image: none;
}

.hr-dark {
  height: 4px !important;
  border-top: none;
  background-color: $primary !important;
  opacity: 1;
}

.test .card {
  border-radius: 0;
}

.test input:invalid:focus {
  border: 1px solid lightpink;
  box-shadow: 0px 0px 5px lightpink inset;
}

.test input:valid:focus {
  border: 1px solid lightgreen;
  box-shadow: 0px 0px 5px lightgreen inset;
}

.colorGreen {
  color: #008000;
}

.colorBlue {
  color: #007bff;
}

.colorDarkOrange {
  color: #816000;
}

.colorOrange {
  color: #d19d00;
}

.page-item.active .page-link {
  background-color: #007bff !important;
  border-color: #007bff;
  color: white;
}

.cardGreen {
  border-color: #008000 !important;
}

.cardBlue {
  border-color: #007bff !important;
}

.colorBlue {
  color: #007bff !important;
}

.colorBrown {
  color: $primary !important;
}

.colorGreen {
  color: #008000 !important;
}

.cHeader {
  background-color: rgb(111, 111, 111) !important;
  color: white !important;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
}

.dangerAnimation {
  animation: fadeIn 1s infinite alternate;
}

#localModal .modal-content {
  height: 100% !important;
  width: 100% !important;
}

#localModal .modal-dialog {
  height: 100% !important;
  width: 100% !important;
}

.lGray {
  background-color: rgb(230, 230, 230);
}

.d-lBlue {
  background-color: rgb(0, 0, 102);
}

.b-LGray {
  border-color: rgb(234, 237, 239);
}

.d-lOrange {
  background-color: rgb(249, 191, 133);
}

#alertTooltip .tooltip-inner {
  max-width: 100%;
  /* min-width: 27vw; */
  /* width: 60vw; */
  /* background-color: rgb(255, 111, 111); */
  /* text-align: start; */
}

@media (min-width: 768px) and (max-width: 6000px) {
  .page-item {
    width: 12vw;
  }

  #delBtn {
    position: absolute;
    right: 0px;
    top: 1vh;
  }
}

@media (max-width: 768px) {
  .page-item {
    width: 22vw;
  }

  #delBtn {
    position: absolute;
    right: 0px;
    top: -35px;
    padding: 0.5em !important;
  }
}

@keyframes blinkRed {
  100%,
  0% {
    fill: #500;
  }

  60% {
    fill: #f00;
  }
}

@keyframes blinkWhite {
  100%,
  0% {
    fill: #000;
  }

  60% {
    fill: #fff;
  }
}

svg {
  max-width: 100%;
  vertical-align: middle;
}

.svg-marker {
  width: 30px;
  height: 30px;
}
.svg-marker-sleep {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  box-shadow: 0px 0px 2px 2px rgb(255, 255, 0) !important;
}

.svg-marker-circle {
  cx: 15px;
  cy: 15px;
  r: 10px;
  stroke-width: 9px;
  fill: rgba(255, 255, 255);
}

.svg-marker-circle-error {
  cx: 15px;
  cy: 15px;
  r: 10px;
  stroke-width: 9px;
  fill: #f00;
}

.svg-marker-circle-alert {
  cx: 15px;
  cy: 15px;
  r: 10px;
  stroke-width: 9px;
  animation: blinkWhite 2s infinite;
}

.svg-marker-circle-alert-red {
  cx: 15px;
  cy: 15px;
  r: 10px;
  stroke-width: 9px;
  animation: blinkRed 2s infinite;
}

.testSvg svg {
  transform: scale(1.5);
}

.pointer {
  cursor: pointer;
}

.checkDays .btn-outline-primary,
.checkAct .btn-outline-primary {
  color: rgb(155, 155, 155) !important;
  background-color: rgb(222, 222, 222) !important;
  border: none;
  border-radius: 7px !important;

  box-shadow: none !important;
}

.checkDays .btn-outline-primary {
  max-width: 5vw;
  min-width: 33px;
  width: 40px;
}

.checkDays .btn-outline-primary:hover {
  /* color: white !important; */
  /* background-color: rgb(42, 215, 5) !important; */
  /* border: none; */
  box-shadow: inset 0px 0px 5px 3px rgb(42, 215, 5) !important;
}

.checkDays .btn-check:checked + .btn-outline-primary,
.checkDays .btn-outline-primary:focus {
  color: white !important;
  background-color: rgb(42, 215, 5) !important;
  box-shadow: none !important;
}

.checkAct .btn-outline-primary:hover {
  /* color: white !important;
  background-color: rgb(0, 191, 255) !important;
  border: none; */
  box-shadow: inset 0px 0px 5px 3px rgb(0, 191, 255) !important;
}

.checkAct .btn-check:checked + .btn-outline-primary,
.checkAct .btn-outline-primary:focus {
  color: white !important;
  background-color: rgb(0, 191, 255) !important;
  box-shadow: none !important;
}

/* .strip:nth-child(odd) {
  border: solid 1px #000000;
  border-radius: 10px;
}

.strip:nth-child(even) {
  border: solid 1px #000000;
  border-radius: 10px;
} */

.strip {
  border: solid 1px #000000;
  border-radius: 5px;
}

/* .strip .card:nth-of-type(even) {
  background-color: white;
} */

/* .accordion-button {
  padding: 0.25rem 1.3rem 0.25rem 1.3rem !important;
} */

.dis > * {
  pointer-events: none;
  filter: grayscale(100%) opacity(85%);
  /* filter: blur(1.2px); */
  user-select: none;
}

#progConfig .active {
  pointer-events: all;
  filter: grayscale(0%) opacity(100%);
  /* filter: blur(0); */
  /* filter: none !important; */
}

.additionalMenu,
.additionalMenu.btn {
  border: none;
  box-shadow: none !important;
  background-color: #fff !important;
}

.additionalMenu:hover,
.additionalMenu:focus {
  border: none;
  box-shadow: none !important;
  background-color: #ccc !important;
}

.mark {
  background-color: #dcdcdc !important;
  padding: 0px 0px 0px 0px !important;
  width: 1.5em !important;
  height: 1.5em !important;
  border-radius: 50%;
  transform: scale(0.7);
}

.drag {
  // position: absolute;
  width: 2.5em;
  height: 3em;
  // padding-left: 10px !important;
  color: rgb(230, 230, 230) !important;
  filter: drop-shadow(0.08em 0.08em 0.08em rgb(111, 111, 111));
}

/* .disAct:disabled {
  pointer-events: all !important;
  cursor: not-allowed !important;
}

.peAll:hover {
  background-color: unset !important;
  color: unset !important;
  /* pointer-events: none !important */

.lh-px {
  line-height: 15px !important;
}

.accordion-button {
  padding: 0rem 0rem 0rem 0rem !important;
}

.accordion-button:not(.collapsed):after {
  filter: grayscale(100) contrast(100) invert(1);
}

.accordion-button:after {
  position: absolute;
  right: 1em;
  filter: grayscale(100) contrast(100) invert(1);
}

.accordion-button:focus {
  box-shadow: none !important;
}

#userData .accordion-button {
  padding: 0.75em 0em 0.75em 0.75em !important;
  background-color: $primary;
  color: white;
}

main {
  position: relative !important;
}

#rightTabs .nav-tabs {
  border-bottom: 1px solid !important;
}

#rightTabs .nav-tabs .nav-link.active {
  border: 3px solid !important;
  border-bottom: none !important;
}

#rightTabs .nav-tabs .nav-link {
  padding-top: 0.5em !important;
  padding-bottom: 0.6em !important;
  padding-left: 0.1em !important;
  padding-right: 0.1em !important;
  font-size: 1em !important;
  border: 1px solid rgb(213, 213, 213) !important;
  /* border-bottom: none !important; */
}

@media (max-width: 768px) {
  #rightTabs {
    position: absolute;
    left: 50px;
    top: 0.25em;
    right: 0;
    overflow-x: auto;
    overflow-y: hidden;
  }

  #rightTabs .nav-tabs {
    /* width: 100% !important; */
    /* border-bottom: 1px solid !important; */
    border-bottom: none !important;
  }

  #rightTabs .nav-tabs .nav-link {
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }
}

@media (min-width: 769px) {
  #rightTabs .nav-tabs .nav-link {
    font-size: 1.2em !important;
    padding-left: 0.5em !important;
    padding-right: 0.5em !important;
  }
}

/* #selectMap input {
  background:  !important;
} */

.disabledCustom * {
  opacity: 0.9 !important;
  // cursor: not-allowed !important;
  pointer-events: none !important;
  user-select: none !important;
}

.position-fixed {
  z-index: 999 !important;
}

/* #userMng {
  height: 95vh !important;
} */

#delUser.btn-primary:hover {
  background-color: red !important;
}

.logo128 {
  background-image: url('~/public/images/logos/logo_redhator_128x128.png');
  background-repeat: no-repeat;
  width: 128px;
  height: 128px;
  transform: scale(0.7);
}

#markerLoading {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  text-align: center;
  /* background-image: url('/public/images/loading@2x.gif'); */
  border-radius: 50%;
  pointer-events: none !important;
}

#switchBetween {
  box-shadow: none;
}

#switchBetween:checked {
  background-color: white;
  background-image: url(~/public/images/chevron_right.svg);
  border-color: lightgray;
}

#switchBetween {
  background-color: white;
  background-image: url(~/public/images/chevron_left.svg);
  border-color: lightgray;
}

.noBoxShadow input {
  box-shadow: none !important;
}

// #firmTable tbody > tr:nth-child(even) {
//   background-color: rgb(242, 242, 242);
// }

#firmTable td {
  max-width: 25vw;
}

#nueva_instalacion_common_block {
  text-align: start !important;
}

.customFocus select:focus {
  box-shadow: none;
  border: 1px solid #ced4da;
}

.customFocus select {
  color: transparent !important;
}

.customFocus select > option {
  color: #212529 !important;
}

.leaflet-popup {
  // width: auto !important;
}

.leaflet-popup-content-wrapper {
  // text-align: center !important;
  // justify-content: center !important;
  // width: 150% !important;
  // margin: 0em !important;
  width: auto !important;
}

.leaflet-popup-content {
  // text-align: center !important;
  // justify-content: center !important;
  // width: auto !important;

  margin: 0.5em -0.5em 0.5em -0.5em !important;
}

.leaflet-popup-content {
  // width: auto !important;
}

.w200 {
  width: 200px;
  margin-left: auto !important;
  margin-right: auto !important;
}

@media (min-width: 768px) {
  .animated {
    overflow: hidden;
    white-space: nowrap;
  }
  .animated.overflowing > strong:hover {
    display: inline-block;
    text-align: start !important;
    position: relative;
    cursor: pointer;
    animation: moveText 3s linear 0s infinite alternate;
  }
}
@keyframes moveText {
  0% {
    transform: translateX(0%);
    left: 0%;
  }
  100% {
    transform: translateX(-100%);
    left: 100%;
  }
}

.modal-90w {
  width: 90vw !important;
  max-width: 90vw !important;
}

/* ----- text responsive ----- */
.text {
  user-select: none;
}

@media (min-width: 100px) and (max-width: 309px) {
  .text {
    font-size: 0.6 * $fontSize !important;
  }
  .text .accordion-header th > p {
    font-size: 0.6 * $fontSize !important;
    padding-right: 2 * $fontSize;
  }
  .text p {
    font-size: 0.9 * $fontSize;
    line-height: 1 * $fontSize !important;
    margin-bottom: 0 * $fontSize !important;
  }
  .text span,
  a {
    font-size: 0.6 * $fontSize;
  }
  .text h6 {
    font-size: 0.8 * $fontSize !important;
  }
  .text h5 {
    font-size: 1 * $fontSize !important;
  }
  .text h4,
  .modal-title {
    font-size: 1.2 * $fontSize !important;
  }
  .text .btn {
    font-size: 0.8 * $fontSize;
    line-height: 1 * $fontSize;
  }
  .text .checkDays {
    display: block !important;
  }
  .text .checkDays > .btn-outline-primary {
    font-size: 1 * $fontSize !important;
  }
  .text .active > .btn {
    padding: 0.5 * $fontSize !important;
  }
  .text .ruleBtn {
    padding: 0.5 * $fontSize !important;
  }
  .text .card-title {
    font-size: 0.8 * $fontSize !important;
    margin-bottom: 0 * $fontSize !important;
  }
  .text .form-select {
    font-size: 0.8 * $fontSize !important;
    padding-left: 0.3 * $fontSize;
  }
  .text .form-control {
    font-size: 0.8 * $fontSize !important;
    padding-left: 0.3 * $fontSize;
    padding-right: 0.3 * $fontSize;
  }
  .text .valueInput,
  .timer {
    font-size: 1.3 * $fontSize !important;
  }
  #captcha {
    transform: scale(0.7) !important;
    transform-origin: 0 0 !important;
  }
}

@media (min-width: 309px) and (max-width: 375px) {
  .text {
    font-size: 0.7 * $fontSize !important;
  }
  #captcha {
    transform: scale(0.85) !important;
    transform-origin: 0 0 !important;
  }
}

@media (min-width: 309px) and (max-width: 425px) {
  .text .accordion-header th > p {
    font-size: 0.8 * $fontSize !important;
    padding-right: 2 * $fontSize;
  }
  // .text span,
  // a {
  //   display: block;
  //   font-size: 0.75 * $fontSize;
  //   line-height: 1.2 * $fontSize !important;
  // }
  .text span.page-link,
  a.page-link {
    display: block;
    font-size: 0.75 * $fontSize !important;
    line-height: 1.2 * $fontSize !important;
  }
  .text p {
    font-size: 1 * $fontSize;
    line-height: 1.2 * $fontSize !important;
    margin-bottom: 0 * $fontSize !important;
  }
  .text h6 {
    font-size: 1 * $fontSize !important;
  }
  .text h5 {
    font-size: 1.2 * $fontSize !important;
  }
  .text h4,
  .modal-title {
    font-size: 1.4 * $fontSize !important;
  }
  .text .btn {
    font-size: 0.97 * $fontSize;
    line-height: 1 * $fontSize;
  }
  .text .checkDays > .btn-outline-primary {
    font-size: 1 * $fontSize !important;
  }
  .text .active > .btn {
    padding: 0.5 * $fontSize !important;
  }
  .text .ruleBtn {
    padding: 0.5 * $fontSize !important;
  }
  .text .card-title {
    font-size: 1 * $fontSize !important;
    margin-bottom: 0 * $fontSize !important;
  }
  .text .form-select {
    font-size: 1 * $fontSize !important;
    padding-left: 0.5 * $fontSize;
  }
  .text .form-control {
    font-size: 1 * $fontSize !important;
    padding-left: 0.5 * $fontSize;
    padding-right: 0.5 * $fontSize;
  }
  .text .valueInput,
  .timer {
    font-size: 1.3 * $fontSize !important;
  }
  .habilitado {
    font-size: 0.9 * $fontSize !important;
  }
}

@media (min-width: 375px) and (max-width: 425px) {
  .text {
    font-size: 0.88 * $fontSize !important;
  }
}

@media (min-width: 425px) and (max-width: 600px) {
  .text {
    font-size: 1 * $fontSize !important;
  }
  // .text span,
  // a {
  //   display: block;
  //   font-size: 0.8 * $fontSize;
  //   line-height: 1.2 * $fontSize !important;
  // }
  .text span.page-link,
  a.page-link {
    display: block;
    font-size: 0.8 * $fontSize !important;
    line-height: 1.2 * $fontSize !important;
  }
  // .text p {
  //     font-size: 1 * $fontSize;
  //     line-height: 1.2 * $fontSize !important;
  //     margin-bottom: 0 * $fontSize !important;
  //   }
  .text .btn {
    font-size: 1 * $fontSize;
    line-height: 1 * $fontSize !important;
  }
  .text .checkDays > .btn-outline-primary {
    font-size: 1 * $fontSize !important;
  }
  .text .active > .btn {
    padding: 0.5 * $fontSize !important;
  }
}

@media (min-width: 600px) and (max-width: 768px) {
  .text {
    font-size: 1 * $fontSize !important;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .text {
    font-size: 1 * $fontSize !important;
  }
  // .text span,
  // a {
  //   display: block;
  //   font-size: 0.9 * $fontSize;
  //   line-height: 1.2 * $fontSize !important;
  // }
  .text p {
    font-size: 0.8 * $fontSize !important;
  }
  .text span.page-link,
  a.page-link {
    display: block;
    font-size: 0.9 * $fontSize !important;
    line-height: 1.2 * $fontSize !important;
  }
  .text .btn {
    font-size: 0.9 * $fontSize;
    line-height: 1 * $fontSize !important;
  }
  .text .active > .btn {
    padding: 0.5 * $fontSize !important;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .text {
    font-size: 1.1 * $fontSize !important;
  }
  .text .active > .btn {
    padding: 0.3 * $fontSize 0.5 * $fontSize 0.3 * $fontSize 0.5 * $fontSize !important;
  }
  // .text .checkAct > .btn-outline-primary {
  //   font-size: 0.8 * $fontSize !important;
  // }
  .text .ruleBtn {
    padding: 0.3 * $fontSize !important;
  }
  .text .form-select {
    font-size: 1 * $fontSize !important;
  }
  .text .form-control {
    font-size: 1 * $fontSize !important;
  }
  .text .valueInput,
  .timer {
    font-size: 1 * $fontSize !important;
  }
}

@media (min-width: 1200px) {
  .text {
    font-size: 1.3 * $fontSize !important;
  }
  .text .active > .btn {
    padding: 0.3 * $fontSize 0.5 * $fontSize 0.3 * $fontSize 0.5 * $fontSize !important;
  }
  .text .ruleBtn {
    padding: 0.3 * $fontSize !important;
  }
  .text .form-select {
    font-size: 1 * $fontSize !important;
  }
  .text .form-control {
    font-size: 1 * $fontSize !important;
  }
  .text .valueInput,
  .timer {
    font-size: 1 * $fontSize !important;
  }
}

.popupBtn {
  font-weight: normal !important;
  font-size: 1 * $fontSize !important;
  line-height: 1 * $fontSize !important;
}

.popup .card-title {
  font-size: 1.2 * $fontSize !important;
}

.deviceText {
  font-size: 1.2 * $fontSize !important;
}

.margin {
  margin-bottom: -0.5em !important;
}
/* -------------------------- */

.gricon path {
  stroke: #adadad;
}

.lineH {
  line-height: 1.3em !important;
}

.noFocus input {
  cursor: default;
}

.noFocus input:focus {
  box-shadow: none;
}

.cursorCopy * {
  cursor: copy;
}

.w-sm-100 {
  width: 100% !important;
}

.w-sm-50 {
  width: 50% !important;
}

@media (min-width: 768px) {
  .w-md-5 {
    width: 5% !important;
  }
  .w-md-25 {
    width: 25% !important;
  }
  .w-md-40 {
    width: 40% !important;
  }
  .w-md-50 {
    width: 50% !important;
  }
}

.inputFocus {
  box-shadow: inset 0px 0px 5px 3px rgba(0, 191, 255, 0.3) !important;
}

.backGray {
  background-color: rgb(199, 199, 199);
}

#markerSleep {
  position: absolute;
  left: 10px;
}
#markerSleep svg {
  filter: drop-shadow(0px 0px 1px rgb(0 0 0 / 1));
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  [type='button'],
  [type='reset'],
  [type='submit'],
  button {
    -webkit-appearance: none !important;
    background-color: transparent;
    -webkit-background-color: transparent;
  }

  #actuatorCheck:disabled {
    opacity: 1;
    -webkit-opacity: 1;
  }

  #actuatorCheck,
  #autoUse {
    background-color: red;
    background-image: url(~/public/images/round.svg);
    border-color: red;
    -webkit-background-color: red;
    -webkit-background-image: url(~/public/images/round.svg);
    -webkit-border-color: red;
  }

  #actuatorCheck:checked,
  #autoUse:checked {
    background-color: green;
    background-image: url(~/public/images/round.svg);
    border-color: green;
    -webkit-background-color: green;
    -webkit-background-image: url(~/public/images/round.svg);
    -webkit-border-color: green;
  }

  input[id*='custom-switch'],
  #c-switch {
    cursor: pointer;
    box-shadow: none;
    -webkit-cursor: pointer;
    -webkit-box-shadow: none;
  }

  input[id*='custom-switch'],
  #c-switch {
    background-color: red !important;
    background-image: url(~/public/images/round.svg) !important;
    border-color: red !important;
    -webkit-background-color: red !important;
    -webkit-background-image: url(~/public/images/round.svg) !important;
    -webkit-border-color: red !important;
  }

  input[id*='custom-switch']:checked,
  #c-switch:checked {
    background-color: green !important;
    background-image: url(~/public/images/round.svg) !important;
    border-color: green !important;
    -webkit-background-color: green !important;
    -webkit-background-image: url(~/public/images/round.svg) !important;
    -webkit-border-color: green !important;
  }

  input[id*='custom-switch'] {
    width: 180px !important;
    height: 40px !important;
    -webkit-width: 180px !important;
    -webkit-height: 40px !important;
  }

  input[id*='custom-switch']:focus,
  #autoUse:focus,
  #c-switch:focus {
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
  }

  #actuatorCheck:checked {
    background-color: green;
    background-image: none;
    -webkit-background-color: green;
    -webkit-background-image: none;
  }

  #actuatorCheck {
    background-color: red;
    background-image: none;
    -webkit-background-color: red;
    -webkit-background-image: none;
  }

  #stateCheck:disabled {
    background-color: gray !important;
    border-color: gray !important;
    background-image: none;
    -webkit-background-color: gray !important;
    -webkit-border-color: gray !important;
    -webkit-background-image: none;
  }

  #stateCheck:checked {
    background-color: green;
    border-color: green;
    background-image: none;
    -webkit-background-color: green;
    -webkit-border-color: green;
    -webkit-background-image: none;
  }

  #stateCheck {
    background-color: red;
    border-color: red;
    background-image: none;
    -webkit-background-color: red;
    -webkit-border-color: red;
    -webkit-background-image: none;
  }

  .form-select {
    display: block;
    width: 100%;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    -moz-padding-start: calc(0.75rem - 3px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e');
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none;
    -webkit-display: block;
    -webkit-width: 100%;
    -webkit-padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    -moz-padding-start: calc(0.75rem - 3px);
    -webkit-font-size: 1rem;
    -webkit-font-weight: 400;
    -webkit-line-height: 1.5;
    -webkit-color: #212529;
    -webkit-background-color: #fff;
    -webkit-background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e');
    -webkit-background-repeat: no-repeat;
    -webkit-background-position: right 0.75rem center;
    -webkit-background-size: 16px 12px;
    -webkit-border: 1px solid #ced4da;
    -webkit-border-radius: 0.375rem;
    -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
  }

  select::-webkit-outer-spin-button,
  select::-webkit-inner-spin-button {
    appearance: none;
    -webkit-appearance: none;
  }

  .drag {
    border: none;
    -webkit-border: none;
  }

  .form-switch .form-check-input {
    width: 2em;
    margin-left: -2.5em;
    background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e');
    background-position: left center;
    border-radius: 2em;
    transition: background-position 0.15s ease-in-out;
    -webkit-width: 2em;
    -webkit-margin-left: -2.5em;
    -webkit-background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e');
    -webkit-background-position: left center;
    -webkit-border-radius: 2em;
    -webkit-transition: background-position 0.15s ease-in-out;
  }

  .form-check-input {
    width: 1em;
    height: 1em;
    margin-top: 0.25em;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid rgba(0, 0, 0, 0.25);
    appearance: none;
    print-color-adjust: exact;
    -webkit-width: 1em;
    -webkit-height: 1em;
    -webkit-margin-top: 0.25em;
    -webkit-vertical-align: top;
    -webkit-background-color: #fff;
    -webkit-background-repeat: no-repeat;
    -webkit-background-position: center;
    -webkit-background-size: contain;
    -webkit-border: 1px solid rgba(0, 0, 0, 0.25);
    -webkit-appearance: none;
    -webkit-print-color-adjust: exact;
  }

  .btn-danger {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
    -webkit-color: #fff;
    -webkit-background-color: #dc3545;
    -webkit-border-color: #dc3545;
  }

  .btn-secondary {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
    -webkit-color: #fff;
    -webkit-background-color: #6c757d;
    -webkit-border-color: #6c757d;
  }
}
